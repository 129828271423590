import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"

const SubHeading = () => {

    return (
        <MySection>
            <BlockPanel id="subheading">
                <Block>
                    Upload your Google Takeout Archives
                </Block>
                <Block>
                    Explore through your Posts, Images and Videos
                </Block>
                <Block>
                Install the plugin and import your content straight into your Wordpress website
                </Block>
            </BlockPanel>
        </MySection>
    
)}

export default SubHeading

const MySection = styled(Section)`
    //padding-bottom : 40px;
`

const BlockPanel = styled(Container)`
    border: 2px;
    display: flex;
    flex-flow: row;
    justify-content: center;
`

const Block = styled.p`
    padding: 20px;
`